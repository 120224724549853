@if (isCircle) {
  <mat-progress-spinner
    class="circle-progress"
    [mode]="'determinate'"
    [diameter]="32"
    [value]="progressPercent()"
  >
  </mat-progress-spinner>
} @else {
  <div class="progress-indicator">
    <div class="progress-bar">
      <span [ngStyle]="{ width: progressPercent() + '%' }"
        >{{ progressPercent() }}%</span
      >
    </div>
  </div>
}
